  .help-button-wrapper {
    position: fixed;
    bottom: 2em;
    right: 2em;
    text-align: right;
    z-index: 0;
    visibility: Visible;
  }
  .help-button {
    height: 3em;
    width: 3em;
    font-size: 1.3em;
    border-radius: 50%;
    border: 1px;
    background: #499459;
    color: #fff;
    cursor: pointer;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    transform: scale(1);
    transition: all 200ms ease;
  }
  .help-button:hover,
  .help-button:focus,
  .help-button:active {
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2);
    outline: 3;
    background: #499459;
    z-index: 10;
  }
  .help-button span {
    display: block;
    font-size: 2em; 
    transform: scale(1);
    transition: transform 100ms ease;
    z-index: 0;

  }
  .help-button:hover span,
  .expanded .help-button span,
  .expanded .help-button span {
    transform: scale(1.25);
  }
  .expanded .help-button {
    transform: rotate(45deg);
    color: #fff;
    background: #499459;
    z-index: 10;
  }

  .help-list {
    list-style: none;
    padding: 15px;
    margin: 10px;
    transition: all 200ms ease;
    transform: translate(0, 90px) scale(0.5);
    transform-origin: bottom center;
    opacity: 0;
    text-align: left;
    z-index: -1;
    display: none;
  }
  .expanded .help-list {
    transform: translate(10px, 0px) scale(1);
    opacity: 1;
    border: 0px solid black;
    background: #ffffff;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.17);
    border-radius: 5px;
    z-index: 10;
    display: block;
  }
  .help-list li {
    margin-bottom: 1em;
    cursor: pointer;
    visibility: visible; /* Initially hidden */

  }
  .help-list a {
    color: #212121;
  }


